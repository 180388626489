<template>
  <div class="progress-bar">
    <div
      class="progress-bar-content"
      style=" border-radius: 10px;margin-bottom: 10px;height: 50px;"
    >
      <!-- <div :style="'background: url(' + require('../assets/step_over_3.png')+');float:left;width:240px;height:40px;'"></div>
    <div :style="'background: url(' + require('../assets/step_over_3.png')+');float:left;width:240px;height:40px;'"></div>
    <div :style="'background: url(' + require('../assets/step_over_3.png')+');float:left;width:240px;height:40px;'"></div>
      <div :style="'background: url(' + require('../assets/step_over_n.png')+');float:left;width:240px;height:40px;'"></div>-->
      <el-steps
        :active="activeIndex"
        simple
        style="clear: both;
    position: absolute;
    
    background-color: rgba(146,154,159, 1);
    margin-left: -60px;"
      >
        <el-step title="1 选择航班" :class="['mstep', activeIndex == 0 ? 'activeStep': '']">
          <i class="step01" slot="icon"></i>
        </el-step>
        <el-step title="2 填写乘机人" :class="['mstep', activeIndex == 1 ? 'activeStep': '']">
          <i class="step02" slot="icon"></i>
        </el-step>
        <el-step title="3 订单确认" :class="['mstep', activeIndex == 2 ? 'activeStep': '']">
          <i class="step03" slot="icon"></i>
        </el-step>
        <el-step title="4 订单支付" :class="['mstep', activeIndex == 3 ? 'activeStep': '']">
          <i class="step04" slot="icon"></i>
        </el-step>
        <el-step title="5 完成出票" :class="['mstep', activeIndex == 4 ? 'activeStep': '']">
          <i class="step05" slot="icon"></i>
        </el-step>
      </el-steps>
    </div>
  </div>
</template>
<script>
export default {
  props: ["activeIndex"]
};
</script>
<style scoped lang="less">
.progress-bar {
  background-color: rgb(146, 154, 159);
  height: 50px;
  &-content {
    width: 1200px;
    margin: 0 auto;
    padding-left: 130px;
  }
}
.progress {
  width: 100%;
  height: 50px;
  margin: auto;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.progress-item {
  font-size: 14px;
  color: #4d4e4e;
  /* width: 15%; */
  width: 200px;
  height: 50px;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  position: relative;
}
.progress .progress-item:last-child {
  margin-right: 0;
}
.progress .progress-item:last-child::after {
  display: none;
}
.progress-item::after {
  right: -50px;
  z-index: 11;
}
.progress-item::before,
.progress-item::after {
  position: absolute;
  top: 0;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 25px solid transparent;
  border-left: 25px solid #ebebeb;
}
.progress .progress-item:first-child::before {
  display: none;
}
.progress-item::before {
  left: 0;
  z-index: 10;
  border-left-color: #fff;
}
.active {
  color: #ffffff;
  background-color: #d44040 !important;
}
.active::after {
  z-index: 11;
  border-left-color: #d44040 !important;
}
/* el-step icon */
.step01,
.step02,
.step03,
.step04,
.step05 {
  width: 20px !important;
  height: 20px !important;
  background-size: 100% 100%;
  margin-top: 50px;
}
.step01 {
  background-image: url("~@/assets/ticket-1.png");
}
.step02 {
  background-image: url("~@/assets/ticket-2.png");
}
.step03 {
  background-image: url("~@/assets/ticket-3.png");
}
.step04 {
  background-image: url("~@/assets/ticket-4.png");
}
.step05 {
  background-image: url("~@/assets/ticket-5.png");
}
/deep/ .el-steps {
  .el-step__icon {
    width: 56px;
    height: 56px;
  }
  .el-step__title {
    color: #ffffff !important;
  }
  .is-finish .step01 {
    background-image: url("~@/assets/ticket-1.png");
  }
  .is-finish .step02 {
    background-image: url("~@/assets/ticket-2.png");
  }
  .is-finish .step03 {
    background-image: url("~@/assets/ticket-3.png");
  }
  .is-finish .step04 {
    background-image: url("~@/assets/ticket-4.png");
  }
  .is-finish .step05 {
    background-image: url("~@/assets/ticket-5.png");
  }
}
.activeStep {
  position: relative;
  // background: #D70039 !important;
  /deep/.el-step__main .el-step__title {
    color: #ffffff !important;
  }
}
.activeStep::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d70039;
  transform: skew(30deg);
}
.mstep {
  width: 380px !important;
  flex-basis: 200px !important;
  padding-left: 30px;
  max-width: 200px;
}
</style>