<template>
  <div class="pop">
        <div>
          <!-- 行李 -->
          <div class="luggage">
            <div v-for="(item, idx) in luggages" :key="idx">
              <div v-if="item.weight == 0" class="luggage-left"> 
                <img src="https://static.a6air.com/sale/baggage/none_luggage.png" class="luggage-left-img">
                <div class="luggage-info">
                  <div class="luggage-info-t">无{{item.baggageType | formatBagType }}</div>
                </div>
              </div>
              <div v-else class="luggage-left">
                <img :src="item.imgUrl" class="luggage-left-img">
                <div class="luggage-info">
                  <div class="luggage-info-t">{{item.baggageType | formatBagType }}</div>
                  <div>至多{{item.weight}}{{item.weightUnit}}<br>
                    体积不超过{{item.size}}{{item.sizeUnit}}<br>
                    {{item.remark}}
                  </div>
                </div>  
              </div> 
            </div>
          </div>
        </div>
  </div>
</template>
<script>
export default {
  props: {
    luggages: {
      type: Array,
      defualt: []
    }
  },
  data() {
    return {};
  },
  filters: {
    formatBagType(val) {
      return val === "CARRIAGE"
        ? "免费非托运行李"
        : val === "CHECK_IN"
          ? "免费托运行李"
          : "";
    }
  },
  watch: {
    luggages: {
      handler(newUnit, oldUnit) {
        console.log(1111, newUnit);
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped>
.pop {
  width: 600px;
  display: flex;
  justify-content: flex-start;
}
.pop .el-tab-pane > div {
  display: flex;
  padding: 6px 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
}
.pop .table {
  font-size: 13px !important;
}
.pop .el-table table td,
.pop .el-table table th {
  padding: 6px !important;
}
.pop .tabs-bar {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 0 16px;
  outline: none;
}
.pop .el-table .cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.pop .el-tabs__item {
  padding: 8px 16px !important;
  /* margin-right: 32px; */
  height: auto;
  line-height: normal;
}
.pop .el-tabs__item:hover {
  color: #f87374;
}
.pop .el-popover {
  padding: 12px 16px !important;
}
.pop .el-tabs__nav-wrap::after {
  height: 1px;
}
.pop .el-tabs__active-bar {
  width: 28px !important;
  background-color: #f87374;
}
.el-tabs__item.is-active {
  color: #f87374;
}
.delete-line {
  text-decoration: line-through;
  color: #ccc;
}
.luggage {
  width: 100%;
}
.luggage-left,
.luggage-right {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.luggage-left-img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.luggage-info-t {
  font-weight: bold;
}
.pop-t {
  display: block;
  font-weight: bold;
}
</style>